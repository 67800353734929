<template>
  <b-container fluid>
    {{ fetchData }}
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';

import RateService from '@/services/osiris/RatesService';

export default {
    components: {
        BContainer,
    },

    data() {
        return {
            fetchData: [],
        };
    },

    created() {
        this.getAllRates();
    },

    methods: {
        async getAllRates() {
            const { data } = await RateService.index();

            this.fetchData = data.data;
        },
    },
};
</script>
